import React from "react"

/**
 *
 * @param {*} param0
 * @returns
 */
const Embed = ({ src, width = 848, height = 480 }) => {
  // const srcDoc = `
  //   <style>
  //     * {padding:0;margin:0;overflow:hidden}
  //     html,body{height:100%}
  //     img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}
  //     span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
  //   </style>
  //   <a href=${src}?start=1>
  //     <span>▶</span>
  //   </a>`
  return (
    <iframe
      width={width}
      height={height}
      src={src}
      allow="fullscreen"
      loading="lazy"
      className="mb-5"
      style={{ maxWidth: width }}
    ></iframe>
  )
}

export default Embed
