import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { LOGIN } from "../constants/routes"
import Embed from "../components/embed"

const Watch = ({ state }) => {
  useEffect(() => {
    const isLoggedIn = state.user.profile
    if (!isLoggedIn) return navigate(LOGIN)
  }, [state.user.profile])

  return (
    <Layout>
      <SEO title="Watch Event Recording" />
      <Banner title="Watch Event Recording" />

      <section id="content">
        <div className="content-wrap" style={{ marginTop: "32px" }}>
          <div className="container clearfix">
            <div className="clearfix">
              <h2>Day 1</h2>
              <h3 className="mb-0">Opening ceremony</h3>
              <Embed src="https://drive.google.com/file/d/1l6qvLq0j5K3LcDTJX3Y4J_kD0tfNl6LE/preview"></Embed>
              <h3 className="mb-0">Building Bridge 1</h3>
              <Embed src="https://drive.google.com/file/d/1Jopomupng6HNKuPSFXys2yN_JQznrXaW/preview"></Embed>
              <h3 className="mb-0">Regulatory and Ethical Conference</h3>
              <Embed src="https://drive.google.com/file/d/1YRy-GuG1ddJiRO5xDNNG9lyf-n95L1HL/preview"></Embed>
              <h3 className="mb-0">Plenary Lecture 1</h3>
              <Embed src="https://drive.google.com/file/d/1PxLjWXYtrCVcL3CiwL-loKW3RQuYcgNB/preview"></Embed>
              <h3 className="mb-0">Parallel Session</h3>
              <h4 className="mb-0">Symposium 1</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/1-2cjydbkF_2AHQlVsb27xV8ubYMeb3Cr/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1IlHegJEPcW7Nkpl2zE8Zy5Ykf4gKPErf/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/19fPMxDPwh-y-op8ye0YXKn0Htczj63hx/preview"></Embed>
              <h4 className="mb-0">Symposium 2</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/10RJ4tGg892H2B9aS92S0jbifIf5tYli3/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1LsG0gKoL4wsxaQyJ3cxg2kjcKvKXSWz5/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1w6PTJ0um2gkeIL6Oju0rNgkDWUwAT6gQ/preview"></Embed>
              <h3 className="mb-0">Scientific Session</h3>
              <h4 className="mb-0">Poster Presentation</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/13SfgTbZaxOUCXTkQxkaDNRNsa83IY6C2/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/15vnvIQJIwPty6F-mK6nh2ahIx4bHQoev/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1k0XGH0X-fzhCOja4AkS6d9wXNUPsWPO4/preview"></Embed>
              <h4 className="mb-0">Oral Presentation 1</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/1So2mc1AcqsJTGrcU7P2jJ3Ck6WeFCAIl/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1p1tFz7zr6nKjvHqU2EHuOTP4LyFJwUMn/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1rcaHjsCRsy1CalrXZvXNbkXXx9V6M14E/preview"></Embed>
              <h4 className="mb-0">Oral Presentation 2</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/1y_W4fiHGvFDB2itT9e418352WetwZ8ML/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1uObYqB3Qo6ZudP9tRj4aYZd1SouVLCUr/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1rcaHjsCRsy1CalrXZvXNbkXXx9V6M14E/preview"></Embed>

              <h2>Day 2</h2>
              <h3 className="mb-0">Building Bridge 2</h3>
              <Embed src="https://drive.google.com/file/d/1Q-agEfaIzjD2ZT1owL7ZQWKDr16wpP9-/preview"></Embed>
              <h3 className="mb-0">Plenary Lecture 2</h3>
              <Embed src="https://drive.google.com/file/d/1u3erGnDsZKzqJbZFrvK25RZZYKj9WExB/preview"></Embed>
              <h3 className="mb-0">Plenary Lecture 3</h3>
              <Embed src="https://drive.google.com/file/d/1ilVTMIhLETC4vUUpi_IIUmC0rkY6IpQr/preview"></Embed>
              <h3 className="mb-0">Parallel Session</h3>
              <h4 className="mb-0">Symposium 3</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/1WrV2-E9Q29dK3DIkBhEHV9e90DBLTYA9/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1YEcb5Hx9-V-Cd3X28ReWR7Lt2B3glA4i/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1rOVsH7cUgJsHCLArg5YqO24QyCcwr-9k/preview"></Embed>
              <h4 className="mb-0">Symposium 4</h4>
              <h4 className="mb-0">Room A</h4>
              <Embed src="https://drive.google.com/file/d/17-clRoWWPgA3Fj7a2W_2DG2Es0hPce1o/preview"></Embed>
              <h4 className="mb-0">Room B</h4>
              <Embed src="https://drive.google.com/file/d/1bcR6YJkXWyKqWYCaXdM1UHYAD-04jxDA/preview"></Embed>
              <h4 className="mb-0">Room C</h4>
              <Embed src="https://drive.google.com/file/d/1-BgNp7lEobPijMhh2Q_SFBEyBLDq_t3c/preview"></Embed>
              <h3 className="mb-0">Closing Ceremony</h3>
              <Embed src="https://drive.google.com/file/d/11YJ7XAUoXFpC5v71raSbmwcKpE1sd5GT/preview"></Embed>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default connect(state => ({ state }))(Watch)
